import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from './services/content.service';
import { EventsService } from './services/events.service';
import { addIcons } from 'ionicons';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  muted: boolean;

  constructor(private route: ActivatedRoute, private contentService: ContentService, private eventsService: EventsService) {
    //getting this from querystring feels like an app thing
    //seems to appear twice - once empty then with val?
    this.route.queryParams.subscribe((p) => {
    	if (p.id) {
    		contentService.load(p.id)
    	}
      else {
        //try to pick up
        contentService.getStored()
      }
    })

    addIcons({
      'volume-up': 'assets/img/icons/Volume_up.svg',
      'volume-off': 'assets/img/icons/Volume_off.svg',
      'pause': 'assets/img/icons/Pause.svg',
      'play': 'assets/img/icons/Play.svg',
      'replay': 'assets/img/icons/Replay.svg'
    });

  }

  toggleMute() {
    this.muted = !this.muted;

    this.eventsService.publish('mute', {
      muted: this.muted,
      time: Date.now()
    })
  }

  get inIframe() {
    return window.location !== window.parent.location;
  }

  gotoSite() {
    top.window.location.href = environment.missionsUrl;
  }
}
