import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  loaded = false;
  content: any = {};
  key: string = 'rscontent2';

  constructor(private http: HttpClient) {

  }

  async getStored() {
    const { value } = await Storage.get({ key: this.key });
    if (value) {
      this.content = JSON.parse(value);
      this.loaded = true;
    }
  }

  async setStored() {
    if (this.content) {
      await Storage.set({
        key: this.key,
        value: JSON.stringify(this.content),
      });
    }
  }

  async clearStored() {
    await Storage.remove({ key: this.key });
  }

  loadMock() {
  	//mock
    
  	this.content = {
      bgImg: {
        url: 'assets/img/eg/bg.jpg'
      },
  		titleImg: {
  			url: 'assets/img/eg/difficult_to_see.svg',
  			alt: 'Difficult to See'
  		},
  		video: 'https://player.vimeo.com/external/656987188.sd.mp4?s=b44c9b7ee3808e205d726ae29e6d984f5106cd7a&profile_id=165%3Chttps://player.vimeo.com/external/656987188.sd.mp4?s=b44c9b7ee3808e205d726ae29e6d984f5106cd7a&profile_id=165',
      poster: {
        url: 'assets/img/eg/bg.jpg',
      },
      instruction: 'Drag across the two things you saw that made it unsafe to cross',
  		safe: false,
      tips: [
        {
          text: 'You can’t see clearly'
        },
        {
          text: 'Someone’s distracting you'
        },
        {
          text: 'Cars are rushing past'
        }
      ],
      options: [
  			{
          img: 'assets/img/shield.svg',
  				text: "Someone's distracting you",
  			},
        {
          img: 'assets/img/shield.svg',
          text: 'Cars are rushing past',
          answer: true
        },
        {
          img: 'assets/img/shield.svg',
          text: 'You can’t see clearly',
          answer: true
        },
        {
          img: 'assets/img/shield.svg',
          text: 'Speeding cyclist'
        },
        {
          img: 'assets/img/shield.svg',
          text: 'Another option'
        },
        {
          img: 'assets/img/shield.svg',
          text: 'And another'
        },
  		],
      options_end: [
        {
          img: 'assets/img/shield.svg',
          text: "Someone's distracting you!",
        },
        {
          img: 'assets/img/shield.svg',
          text: 'Cars are rushing past!'
        },
        {
          img: 'assets/img/shield.svg',
          text: 'You can’t see clearly!'
        },
        {
          img: 'assets/img/shield.svg',
          text: 'Speeding cyclist!'
        },
        {
          img: 'assets/img/shield.svg',
          text: 'Another option!'
        },
        {
          img: 'assets/img/shield.svg',
          text: 'And another!'
        },
      ],
      congratsText: "Great skills Roadstar! You saw all the reasons it's unsafe to cross.",
  		endText: "We've learned that we don't cross when:",
      numAnswers: 2
  	}
    this.loaded = true;
  }

  async load(id) {
    console.log('ContentService::load', id);

    this.loaded = false;
    this.content = {};

    //should we check we don;t already have this ID's content stored?

    let url = environment.contentUrl(id);
    let res:any = await this.http.get(url).toPromise();
    console.log(res);

    if (res.acf) {
      const numAnswers = res.acf.options.reduce((counter, option) => {
        if (option.answer) {
          counter++;
        }
        return counter;
      }, 0); 

      this.content = {
        bgImg: {
          url: res.acf.background_image.url || res.acf.video_poster.url
        },
        titleImg: {
          url: res.acf.title_image.url,
          alt: res.acf.title_image.alt
        },
        video: res.acf.video_url,
        poster: {
          url: res.acf.video_poster.url,
          alt: res.acf.video_poster.alt
        },
        instruction: res.acf.instruction,
        safe: res.acf.safe, 
        options: [],
        options_end: [],
        tips: [],
        numAnswers: numAnswers, 
        congratsText: res.acf.congrats_text,
        endText: res.acf.end_text
      };

       //used to have images now just text

      res.acf.options.forEach( (el) => {
        this.content.options.push({
          img: 'assets/img/shield.svg',
          text: el.text,
          answer: el.answer
        })
      });

      res.acf.tips.forEach( (el) => {
        this.content.tips.push({
          text: el.text
        })
      });

      res.acf.summary.forEach( (el) => {
        this.content.options_end.push({
          img: 'assets/img/shield.svg',
          text: el.text
        })
      });

      //resolve video redirect
      let hres = await this.http.head(res.acf.video_url, {observe: 'response'}).toPromise();
      console.log(hres);

       if (hres.url) {
         this.content.video = hres.url;
       }

       this.loaded = true;
       
      this.setStored()
    }
    else {
      //problem, worth a go
      console.error(res);
      this.getStored();
    }

  }

  get() {
  	return this.content;
  }

  get bgImg() {
    return this.content.bgImg;
  }

  get titleImg() {
  	return this.content.titleImg;
  }

  get video() {
  	return this.content.video;
  }

  get poster() {
    return this.content.poster;
  }

  get instruction() {
    return this.content.instruction;
  }
  
  get options() {
  	return this.content.options;
  }

  get options_end() {
    return this.content.options_end;
  }

  get numAnswers() {
    return this.content.numAnswers;
  }

  get tips() {
    return this.content.tips;
  }

  get safe() {
    return this.content.safe;
  }

  get congratsText() {
    return this.content.congratsText;
  }

  get endText() {
    return this.content.endText;
  }
}
