import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { APP_BASE_HREF } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
  	BrowserModule, 
  	IonicModule.forRoot(), 
  	AppRoutingModule, 
  	ServiceWorkerModule.register('ngsw-worker.js', {
	  enabled: environment.production,
	  // Register the ServiceWorker as soon as the app is stable
	  // or after 30 seconds (whichever comes first).
	  registrationStrategy: 'registerWhenStable:30000'
	})
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_BASE_HREF,
      useFactory:()=>{
          return environment.base;
      } 
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
